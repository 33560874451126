<template>
  <div>
    <div class="bg-[#fff] mb-6 p-4">
      <p class="font-lg font-bold py-3">预算&排期</p>
      <el-form
        ref="form"
        :model="formData"
        label-width="120px"
        :rules="rules"
      >
        <el-form-item
          label="组预算"
          prop="budgetAmount"
        >
          <el-input
            v-model="formData.budgetAmount"
            style="width: 320px"
            type="number"
            placeholder="请输入预算金额"
            @blur="changeBudget"
            >" >
            <el-select
              v-model="formData.budgetType"
              placeholder=""
              slot="prepend"
              class="w-32"
              @change="setTableData('budgetType', formData.budgetType)"
            >
              <el-option
                v-for="item in budgetTypes"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              >
              </el-option>
            </el-select>
            <span slot="append">{{ currency }}</span>
          </el-input>
        </el-form-item>
        <div class="flex">
          <el-form-item
            label="组排期"
            prop="startTime"
          >
            <el-date-picker
              v-model="formData.startTime"
              type="datetime"
              placeholder="开始时间"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd HH:mm:ss"
              @change="changeStartTime"
            ></el-date-picker>
          </el-form-item>
          <span class="mx-2">~</span>
          <el-form-item
            label=""
            label-width="0"
            prop="endTime"
          >
            <el-date-picker
              v-model="formData.endTime"
              type="datetime"
              placeholder="结束时间"
              @change="selectEndTime"
              :picker-options="pickerOptions1"
              value-format="yyyy-MM-dd HH:mm:ss"
            ></el-date-picker>
          </el-form-item>
        </div>
        <el-form-item
          label="目标"
          prop="optimizationGoal"
        >
          <div class="w-24 inline-block text-center trance">网站转化</div>
          <el-select
            v-model="formData.optimizationGoal"
            placeholder=""
            style="width: 200px"
            @change="setTableData('optimizationGoal', formData.optimizationGoal)"
          >
            <el-option
              v-for="item in optimizationGoals"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="优化期限"
          prop="conversionWindow"
        >
          <el-select
            v-model="formData.conversionWindow"
            placeholder=""
            class="w-96"
            @change="setTableData('conversionWindow', formData.conversionWindow)"
          >
            <el-option
              v-for="item in conversionWindows"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="竞价策略"
          prop="bidStrategy"
        >
          <el-radio-group
            v-model="formData.bidStrategy"
            @change="selectBid"
          >
            <el-radio
              :label="item.key"
              v-for="item in bidStrategys"
              >{{ item.value }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label=""
          v-if="formData.bidStrategy != 'AUTO_BID'"
          prop="bidAmount"
        >
          <el-input
            v-model="formData.bidAmount"
            placeholder="请输入出价金额"
            style="width: 240px"
            @blur="changeBid"
            type="number"
          >
            <span slot="append">{{ currency }}/转化</span>
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="bg-[#fff] p-4">
      <p class="font-lg font-bold py-3">投放预览</p>
      <split-table
        v-model="splitTableMsg.adGroups"
        :enumConstants="enumConstants"
        :currency="currency"
        v-loading="splitViewLoading"
        :checkData="checkData"
        :getBudgetMin="getBudgetMin"
        ref="splitTable"
      >
        <template slot="campaignName">
          <div class="campaignName">
            <el-form
              ref="campaignNameForm"
              :model="splitTableMsg"
              label-width="120px"
              class="w-full"
            >
              <el-form-item
                label="广告系列名称："
                prop="name"
                :rules="[{ validator: validatorName, trigger: ['blur', 'change'] }]"
              >
                <el-input v-model="splitTableMsg.name"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </template>
      </split-table>
    </div>
    <config-upload
      :creatFormShow.sync="creatFormShow"
      @creatAd="creatAd"
    ></config-upload>
  </div>
</template>
<script>
import { splitTableView, params } from './index';
import splitTable from './splitTable.vue';
import { queryConfigDataCheck } from '@/api/multiPlatform';
import { createAdPreview, uploadAd } from '@/api/snapchat/create';
import configUpload from '../configUpload/index.vue';
import moment from 'moment';
import axios from 'axios';
export default {
  props: {
    value: Object,
    enumConstants: Object,
    allAdAccountId: Array,
    params: Object,
  },
  data() {
    return {
      formData: {
        budgetAmount: 20,
        budgetType: 'DAILY_BUDGET',
        optimizationGoal: 'PIXEL_PURCHASE',
        bidStrategy: 'AUTO_BID',
        bidAmount: '',
        conversionWindow: 'SWIPE_28DAY_VIEW_1DAY',
        startTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        endTime: '',
      },
      splitTableView,
      splitTableMsg: {
        campaignName: '',
        adGroups: [],
      },
      checkData: {},
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      pickerOptions1: {
        disabledDate: this.disabledDate,
      },
      splitViewLoading: false,
      creatFormShow: false,
      // 取消上一次请求
      source: null,
    };
  },
  components: {
    splitTable,
    configUpload,
  },
  computed: {
    bidStrategys() {
      return this.enumConstants.bidStrategies || [];
    },
    budgetTypes() {
      return this.enumConstants.budgetTypes || [];
    },
    conversionWindows() {
      return this.enumConstants.conversionWindows || [];
    },
    optimizationGoals() {
      let list = [];
      console.log(this.params.creatives);
      let creativeTypeList = [...new Set(this.params.creatives.map((v) => v.type))];
      console.log(creativeTypeList);
      creativeTypeList.forEach((item) => {
        let cur = this.enumConstants.creativeTypes.find((v) => v.key == item);
        cur ? list.push(cur.listRef) : null;
      });
      let list1 = [];
      let optimizationGoals = this.enumConstants.optimizationGoals;
      //   let list2 = optimizationGoals.map((v) => list.every((item) => item.listRef.map((m) => m.key).includes(v.key)));
      console.log(list);
      console.log(this.intersectionByKey(list, 'key'));
      return this.intersectionByKey(list, 'key');
    },
    rules() {
      return {
        budgetAmount: [
          {
            validator: (rule, value, callback) => {
              if (!value) callback('预算不能为空');
              if (this.formData.budgetType == 'LIFETIME_BUDGET') {
                if (this.formData.startTime && this.formData.endTime && value < this.getBudgetMin()) {
                  return callback(`预算在${this.getBudgetMin()}～${this.checkData.adsetBudgetMax}区间`);
                }
              } else if (value && (value < this.checkData.adsetBudgetMin || value > this.checkData.adsetBudgetMax)) {
                return callback(`预算在${this.checkData.adsetBudgetMin}～${this.checkData.adsetBudgetMax}区间`);
              }
              callback();
            },
            trigger: ['blur', 'change'],
          },
        ],
        startTime: [{ required: true, message: '请选择开始时间', trigger: ['change', 'blur'] }],
        endTime: [
          {
            validator: (rule, value, callback) => {
              if (this.formData.budgetType == 'LIFETIME_BUDGET') {
                if (!value) {
                  callback('预算类型是总预算的时候,结束时间必填');
                }
                if (new Date(value).getTime() < new Date(this.formData.startTime).getTime()) {
                  return callback('结束时间不能小于开始时间');
                }
              }
              callback();
            },
            trigger: ['blur', 'change'],
          },
        ],
        bidAmount: [
          {
            validator: (rule, value, callback) => {
              if (value && (value < this.checkData.bidMin || value > this.checkData.bidMax)) {
                return callback(true);
              } else if (!value) {
                return callback(true);
              }
              callback();
            },
            message: `出价在${this.checkData.bidMin}～${this.checkData.bidMax}区间`,
            trigger: ['blur', 'change'],
          },
        ],
        bidStrategy: [{ required: true, message: '请选择竞价类型', trigger: ['change', 'blur'] }],
        optimizationGoal: [{ required: true, message: '请选择优化目标', trigger: ['change', 'blur'] }],
        conversionWindow: [{ required: true, message: '请选择转化窗口', trigger: ['change', 'blur'] }],
      };
    },
    activeAccount() {
      return this.allAdAccountId.find((v) => v.accountId == this.params.adAccountId) || {};
    },
    currency() {
      return this.activeAccount.currencyCode || 'USD';
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val && Object.keys(val).length) {
          console.log(val);
          this.formData = { ...val };
        }
      },
    },
  },
  methods: {
    intersectionByKey(arrays, key) {
      if (arrays.length === 0) return [];

      // Start with the first array's keys as a Set for comparison
      let result = arrays[0].map((item) => item[key]);

      // Reduce to find the intersection
      for (let i = 1; i < arrays.length; i++) {
        const currentKeys = new Set(arrays[i].map((item) => item[key]));
        result = result.filter((value) => currentKeys.has(value));
      }

      // Filter original objects based on the intersected keys
      return arrays.map((array) => array.filter((item) => result.includes(item[key])))[0];
    },
    disabledDate(time) {
      return time.getTime() < Date.now() - 8.64e7 || time.getTime() < new Date(this.formData.startTime).getTime();
    },
    // 根据结束日期，计算最小预算
    getBudgetMin() {
      let start = moment(this.formData.startTime).format('YYYY-MM-DD');
      let end = moment(this.formData.endTime).format('YYYY-MM-DD');
      let days = moment(end).diff(start, 'days');
      return this.checkData.adsetBudgetMin * (days + 1);
    },
    // 获取校验规则
    getConfigCheck() {
      queryConfigDataCheck({ platform: 'snapchat', currency: this.activeAccount?.currencyCode || 'USD' }).then(
        (res) => {
          console.log(res);
          this.checkData = res.data;
        },
      );
    },
    // 修改预算
    changeBudget(v) {
      console.log(v);
      this.formData.budgetAmount = (this.formData.budgetAmount / 1).toFixed(2);
      //   this.splitView();
      this.setTableData('budgetAmount', this.formData.budgetAmount);
      this.setTableData('startTime', this.formData.startTime);
      this.setTableData('endTime', this.formData.endTime);
      this.setTableData('budgetType', this.formData.budgetType);
    },
    // 修改出价
    changeBid(v) {
      this.formData.bidAmount = (this.formData.bidAmount / 1).toFixed(2);
      //   this.splitView();
      this.setTableData('bidAmount', this.formData.bidAmount);
      this.setTableData('bidStrategy', this.formData.bidStrategy);
    },
    // 修改开始时间
    changeStartTime() {
      this.setTableData('startTime', this.formData.startTime);
      this.setTableData('endTime', this.formData.endTime);
      this.setTableData('budgetAmount', this.formData.budgetAmount);
    },
    // 总预算设置结束日期的时候校验预算
    selectEndTime() {
      this.$refs.form.validateField('budgetAmount');
      //   this.splitView();
      this.setTableData('startTime', this.formData.startTime);
      this.setTableData('endTime', this.formData.endTime);
      this.setTableData('budgetAmount', this.formData.budgetAmount);
    },
    // 选择竞价策略
    selectBid(v) {
      if (v == 'AUTO_BID') {
        this.formData.bidAmount = null;
        this.setTableData('bidAmount', null);
      }
      //   this.splitView();
      this.setTableData('bidStrategy', this.formData.bidStrategy);
    },
    // 对外验证
    validate() {
      let list = [];
      list.push(this.$refs.form.validate());
      return Promise.all(list);
    },
    cancelQuest() {
      if (typeof this.source === 'function') {
        // console.log('this.source',this.source)
        this.source(); //取消请求
      }
    },
    splitView() {
      this.$emit('input', this.formData);
      this.$refs.form.validate((validate) => {
        if (validate) {
          this.getSplitTableView();
        }
      });
    },
    // 处理受众
    setInterest(targeting) {
      let list = targeting.map((v) => {
        v.geos = v.geos.map((geo) => {
          if (geo.countryCode) return geo;
          return { countryCode: geo };
        });
        v.interests = [
          {
            categoryId: Array.from(new Set([].concat(...Object.values(v.interests)))),
            operation: 'INCLUDE',
          },
        ];
        v.segments = v.segments.filter((segments) => {
          if (segments.segmentId.length) {
            return segments;
          }
        });
        return v;
      });
      return list;
    },
    // 设置表格单项数据
    setTableData(key, value) {
      this.$emit('input', this.formData);
      this.$refs.form.validate((validate) => {
        if (validate) {
          this.splitTableMsg.adGroups.forEach((group) => {
            group[key] = value;
          });
        }
      });
    },
    // 拆分表格
    getSplitTableView() {
      this.cancelQuest();
      let params = JSON.parse(JSON.stringify(this.params));
      params.adGroupOption = this.formData;
      params.adAccountName = this.activeAccount?.accountName;
      params.adGroupTargeting = this.setInterest(params.adGroupTargeting);
      params.creatives = params.creatives.map((creative) => {
        let { infos, additionalCreativeInfo, ...others } = creative;

        creative.infos = creative.infos.map((info) => {
          return {
            name: info.name,
            mediaUrl: info.url,
            headline: info.headline,
            mediaType: info.mediaType,
            landingPageUrl: others.webViewUrl,
            ...others,
            webViewUrl: null,
          };
        });
        console.log(creative.infos);
        // 故事广告，只传一个info,对应多个素材
        if (creative.type == 'COMPOSITE') {
          let compositeMedia = creative.infos.map((v) => {
            return {
              mediaType: v.mediaType, //素材类型：【VIDEO】视频，【IMAGE】图像
              compositeMediaUrl: v.mediaUrl, //故事广告的素材地址
            };
          });
          creative.infos = [{ ...creative.infos[0], compositeMedia }];
        }

        return creative;
      });
      this.splitTableMsg = {
        campaignName: '',
        adGroups: [],
      };

      this.splitViewLoading = true;
      createAdPreview(params, this)
        .then((res) => {
          this.splitViewLoading = false;
          if (res.code == 0) {
            this.splitTableMsg = res.data;
          } else {
            this.splitTableMsg = {
              campaignName: '',
              adGroups: [],
            };
          }
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
          } else {
            this.splitViewLoading = false;
            this.splitTableMsg = {
              campaignName: '',
              adGroups: [],
            };
          }
        });
    },
    // 校验广告系列名称
    validatorName(rule, value, callback) {
      if (!value) {
        return callback('名称不能为空');
      }
      if (value.length > 375) {
        return callback('广告名称长度不能超过375字符');
      }
      callback();
    },
    // 点击创建
    creatAdsClick() {
      this.$refs.form.validate((validate) => {
        if (validate) {
          if (this.splitTableMsg.adGroups.length > 0) {
            this.$refs.campaignNameForm.validate((res) => {
              console.log(res);
              if (res) {
                this.$refs.splitTable.validate().then((res) => {
                  this.creatFormShow = true;
                });
              }
            });
          } else {
            this.$message({
              type: 'warning',
              message: '请重新进行拆分',
            });
          }
        }
      });
    },
    // 创建广告
    creatAd(params) {
      let lastParams = { ...this.splitTableMsg, ...params };
      console.log(lastParams);
      this.$showLoading();
      uploadAd(lastParams).then((res) => {
        this.$hideLoading();
        this.creatFormShow = false;
        this.$confirm('跳转到草稿箱，或者重新创建一个广告', '提示', {
          confirmButtonText: '再创建一个',
          cancelButtonText: '回到列表页',
        })
          .then((res) => {
            this.$parent.current = 1;
          })
          .catch((res) => {
            this.$router.push({ name: 'multiPlatform' });
          });
      });
    },
  },
  created() {
    this.getConfigCheck();
    this.getSplitTableView();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.el-form-item .el-select .el-input__inner {
  padding-left: 90px;
}

.campaignName {
  display: flex;
  align-items: center;
  .label {
    flex: 100px 0 0;
  }
  .input {
    flex: 1;
  }
}
.trance {
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  height: 40px;
  border-right: none;
  margin-right: -3px;
  background-color: #f5f7fa;
  color: #909399;
}
</style>
